import ApplyForm from './components/ApplyForm';

function ApplyCV() {
  return (
    <>
      <ApplyForm/>
    </>
  );
}

export default ApplyCV;
