import { useRef } from 'react';
import {
  Navbar,
  Container,
  Nav,
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import logoImg from '../assets/img/logo.png'
import { scrollToElement } from '../ScrollToTop';

function Header() {
  const toggleButtonRef = useRef()

  const hideNavCollapse = () => {
    if (!toggleButtonRef.current.classList.contains('collapsed')) {
      toggleButtonRef.current.click()
    }
  }

  return (
    <Navbar expand="lg" className="py-3" fixed="top" bg="white">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand onClick={() => scrollToElement('home')}>
            <img
              src={logoImg}
              width="150"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" ref={toggleButtonRef} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <LinkContainer to="/">
              <Nav.Link className="mx-2" onClick={() => { hideNavCollapse(); scrollToElement('home') }}>About us</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/#jobs">
              <Nav.Link className="mx-2" onClick={() => { hideNavCollapse(); scrollToElement('jobs') }} href="/#jobs">Jobs</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/#benefits">
              <Nav.Link className="mx-2" onClick={() => { hideNavCollapse(); scrollToElement('benefits') }} href="/#benefits">Benefits</Nav.Link>
            </LinkContainer>
            <LinkContainer to="#contact">
              <Nav.Link className="mx-2" onClick={() => { hideNavCollapse(); scrollToElement('contact') }} href="#contact">Contact</Nav.Link>
            </LinkContainer>
            <Nav.Item className="nav-link pt-5px">
              <a href="https://www.facebook.com/astralertechnology/"><i className="fa-brands fa-facebook me-3 SocialLink"></i></a>
              <a href="https://www.linkedin.com/company/astraler-technology/"><i className="fa-brands fa-linkedin SocialLink"></i></a>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
