const REACT_APP_API_URL = process.env.REACT_APP_API_URL || "";

export const apiUrl = REACT_APP_API_URL

export const get = async (uri) => {
  const token = localStorage.getItem("token");
  const headers = {
    'Content-Type': 'application/json'
  }
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  const request = new Request(REACT_APP_API_URL + uri, {
    method: 'GET',
    headers: new Headers(headers)
  })
  const response = await fetch(request)
  return response.json()
}

export const post = (uri, data, isFormdata = false) => {
  const token = localStorage.getItem("token");
  const headers = {}
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  if (!isFormdata) {
    headers['Content-Type'] = 'application/json'
  }
  const request = new Request(REACT_APP_API_URL + uri, {
    method: 'POST',
    body: isFormdata ? data : JSON.stringify(data),
    headers: new Headers(headers)
  })
  return fetch(request)
}

export const put = (uri, data) => {
  const token = localStorage.getItem("token");
  const headers = {
    'Content-Type': 'application/json'
  }
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  const request = new Request(REACT_APP_API_URL + uri, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: new Headers(headers)
  })
  return fetch(request)
}

export const patch = (uri, data) => {
  const token = localStorage.getItem("token");
  const headers = {
    'Content-Type': 'application/json'
  }
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  const request = new Request(REACT_APP_API_URL + uri, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: new Headers(headers)
  })
  return fetch(request)
}

export const upload = file => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("image", file.rawFile);
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL || "";
    fetch(REACT_APP_API_URL + "/api/v1/uploads", {
      method: "post",
      body: formData
    })
      .then(response => response.json())
      .then(image => resolve(image))
      .catch(err => reject(err));
  });
};
