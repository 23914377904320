import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import logoImg from "../assets/img/logo.png";
import { scrollToElement } from "../ScrollToTop";

function Footer(props) {
  return (
    <Container className="Footer" {...props}>
      <Row className="mt-5 mb-3">
        <Col md={4}>
          <Link to="/" onClick={() => scrollToElement("home")}>
            <img
              src={logoImg}
              width="150"
              className="mb-5"
              alt="React Bootstrap logo"
            />
          </Link>
        </Col>
        <Col md={4}>
          <div className="Hairline-Small mb-3">Contact us</div>
          <div className="Text-Body-2 mb-3">
            <a className="text-danger" href="mailto:info@astraler.com">
              info@astraler.com
            </a>
          </div>
          <div className="Text-Body-2 my-3">
            6th Floor, Post Office Building,
            <br />
            271 Nguyen Van Linh st.,
            <br />
            Vinh Trung ward, Thanh Khe district, Da Nang
          </div>
        </Col>
        <Col md={4}>
          <div className="Hairline-Small mb-3">Follow us</div>
          <div className="Text-Body-2 mb-3">
            <a href="https://www.facebook.com/astralertechnology/">
              <i className="fa-brands fa-facebook me-3 SocialLink"></i>
            </a>
            <a href="https://www.linkedin.com/company/astraler-technology/">
              <i className="fa-brands fa-linkedin me-3 SocialLink"></i>
            </a>
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="d-md-flex justify-content-between mb-5">
        <Col xs={12} md={6} className="Text-Small mb-3">
          &copy; 2022 Astraler. All rights reserved.
        </Col>
        <Col
          xs={12}
          md={6}
          className="Text-Small d-md-flex justify-content-end mb-3"
        >
          <a href="/privacy-policy" className="me-4 Text-Small">
            Privacy
          </a>
          <a href="/terms" className="Text-Small">
            Terms
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
