import {
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { get } from '../api'
import { LinkContainer } from 'react-router-bootstrap'
import FAQs from './FAQs'

function JobDetails({ jobId }) {
  const [job, setJob] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const { data } = await get(`/api/v1/jobs?slug=${jobId}`)
      if (data.length && data[0]) {
        setJob(data[0])
      } else {
        const { data } = await get(`/api/v1/jobs/${jobId}`)
        setJob(data)
      }
    }
    fetchData()
  }, [jobId])

  return (
    <div className="JobDetails">
      <Container>
        <div className="mb-5 pb-5">
          <LinkContainer to="/#jobs">
            <Button variant="link" className="text-dark ms-0 ps-0 Button-Small">
              <i className="fa-solid fa-left-long mx-2"></i>
              BACK TO ALL POSITIONS
            </Button>
          </LinkContainer>
        </div>
        {!!job && <Row>
          <Col xs={12} lg={6}>
            <h2>{job.name}</h2>
            <div className="Text-Body-2">{job.location}</div>
            <div className="my-5">
              <LinkContainer to={`/apply?jobId=${jobId}`}>
                <Button variant="danger" size="lg" className="bg-red">
                  Apply Now
                  <i className="fa-solid fa-right-long ms-2 fs-10"></i>
                </Button>
              </LinkContainer>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="Text-Body-1 my-3">Role & Responsibilities</div>
            <div className="Text-Body-2 pb-2">
              <ul>
                {(job.roles || '').split('\n').map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
            <div className="Text-Body-1 my-3">Requirements</div>
            <div className="Text-Body-2 pb-5">
              <ul>
                {(job.requirements || '').split('\n').map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>}
        <Row>
          <Col xs={12} lg={6}></Col>
          <Col xs={12} lg={6}>
            <FAQs/>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default JobDetails;
