import { Routes, Route } from "react-router-dom";
import ApplyCV from "./ApplyCV";
import Footer from "./components/Footer";
import Header from './components/Header';
import EnRoute from "./EnRoute";
import Home from './Home';
import Job from './Job';
import NotFound from "./NotFound";
import Privacy from "./Privacy";
import ScrollToTop from "./ScrollToTop";
import Terms from "./Terms";
import MathGPTSupport from "./MathGPTSupport";
import Support from "./Support";

function App() {
  return (
    <div className="App" id="home">
      <ScrollToTop/>
      <Header/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="jobs/:id" element={<Job />} />
        <Route path="apply" element={<ApplyCV />} />
        <Route path="terms" element={<Terms />} />
        <Route path="privacy-policy" element={<Privacy />} />
        <Route path="enRoute-support" element={<EnRoute />} />
        <Route path="mathgpt-support" element={<MathGPTSupport />} />
        <Route path="support" element={<Support />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer id="contact"/>
    </div>
  );
}

export default App;
