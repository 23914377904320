import {
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import businessImg from '../assets/img/business.png'
import lifestyleImg from '../assets/img/lifestyle.png'
import productivityImg from '../assets/img/productivity.png'
import utilityImg from '../assets/img/utility.png'
import { scrollToElement } from '../ScrollToTop'

function OurProducts(props) {
  return (
    <Container className="OurProducts" {...props}>
      <div className="Hairline-Large text-center">Our Products</div>
      <h2 className="text-center mt-2 mb-5">We published apps in categories such as</h2>
      <Row className="mt-2">
        <Col lg={6} className="mb-4">
          <div className="bg-silver">
            <div className="bg-image">
              <img src={businessImg} alt="Business" />
              <div className="text-absolute">
                <h4 className="mt-3 text-shadow">Business</h4>
                <div className="Text-Body-2 mt-2">Solutions for professionals</div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} className="mb-4">
          <div className="bg-silver">
            <div className="bg-image">
              <img src={lifestyleImg} alt="Lifestyle" />
              <div className="text-absolute">
                <h4 className="mt-3 text-shadow">Lifestyle</h4>
                <div className="Text-Body-2 mt-2">Care for each and every aspect of your life</div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} className="mb-4">
          <div className="bg-silver">
            <div className="bg-image">
              <img src={productivityImg} alt="Productivity" />
              <div className="text-absolute">
                <h4 className="mt-3 text-shadow">Productivity</h4>
                <div className="Text-Body-2 mt-2">Let technology help you keep up with the hustle</div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} className="mb-4">
          <div className="bg-silver">
            <div className="bg-image">
              <img src={utilityImg} alt="Utility" />
              <div className="text-absolute">
                <h4 className="mt-3 text-shadow">Utility</h4>
                <div className="Text-Body-2 mt-2">Simplify your daily tasks</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="text-center my-5 py-5">
        <LinkContainer to="/#jobs">
          <Button variant="outline-secondary" size="lg" onClick={() => scrollToElement('jobs')}>Let's create together</Button>
        </LinkContainer>
      </div>
    </Container>
  );
}

export default OurProducts;
