import { useEffect, useRef, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom';
import * as yup from "yup";
import { get, post, upload as uploadFile } from '../api';
import likeImg from '../assets/img/like.png'
import FAQs from './FAQs';

const validationSchema = yup.object().shape({
  job: yup.string().required(),
  name: yup.string().required(),
  email: yup.string().required(),
  phone: yup.string().required(),
  letter: yup.string().required(),
})

function ApplyForm() {
  const hiddenFileInput = useRef(null)
  const [data, setData] = useState({
    job: '',
    name: '',
    email: '',
    phone: '',
    letter: '',
    cv: {},
  })
  const [fileCV, setFileCV] = useState(null)
  const [errors, setErrors] = useState({})
  const [jobs, setJobs] = useState([])
  const [done, setDone] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    async function fetchData() {
      const { data: jobs } = await get('/api/v1/jobs?limit=1000&open=true&sort=-priority')
      setJobs(jobs)
      const jobId = searchParams.get('jobId')
      if (jobId) {
        const { data: jobData } = await get(`/api/v1/jobs?slug=${jobId}`)
        if (jobData && jobData.length) {
          setData({
            ...data,
            job: jobData[0]._id,
          })
        } else {
          setData({
            ...data,
            job: jobId,
          })
        }
      } else {
        setData({
          ...data,
          job: jobs[0]._id,
        })
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (fileCV) {
      upload(fileCV, 'cv')
    }
  }, [fileCV])

  const handleChangeData = e => {
    delete errors[e.target.name]
    setErrors(errors)
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrors({})
    try {
      await validationSchema.validate(data, { abortEarly: false })
    } catch (err) {
      const errors = {}
      for (const error of err.inner) {
        errors[error.path] = error
      }
      setErrors(errors)
      return
    }
    try {
      const formData = {...data}
      const response = await post('/api/v1/curriculumVitaes', formData)
      if (response.status < 200 || response.status >= 300) {
        const body = await response.json()
        if (body?.error?.errors) {
          const errors = {}
          for (const error of body.error.errors) {
            error.path = error.path ?? error.message.split(' ').shift()
            errors[error.path] = error
          }
          return setErrors(errors)
        }
        setErrors({
          cv: {
            message: 'Error: Something went wrong'
          }
        })
        return
      }
      setDone(true)
    } catch (err) {
      setErrors({
        cv: {
          message: 'Error: ' + err.message
        }
      })
    }
  }

  const upload = async (file, field) => {
    setProcessing(true)
    try {
      if (file?.size > 10 * 1024 * 1024) {
        return setErrors(errors => ({
          ...errors,
          [field]: {
            message: 'File size must not exceed 10MB.'
          }
        }))
      }
      const upload = await uploadFile({ rawFile: file })
      if (upload.data.url) {
        setData(data => ({
          ...data,
          [field]: {
            src: upload.data.url
          }
        }))
        setErrors(errors => {
          const newErrors = {...errors}
          delete newErrors[field]
          return newErrors
        })
      }
    } catch (err) {
      setErrors(errors => ({
        ...errors,
        [field]: {
          message: 'There was an error while uploading the file. Please check the file again and try again.'
        }
      }))
    }
    setProcessing(false)
  }

  if (done) {
    return (
      <Container className="ApplyForm">
        <Row className="justify-content-md-center">
          <Col md={6}>
            <div className="mb-4">
              <img src={likeImg} width={96} alt="Icon"/>
            </div>
            <h2 className="d-none d-lg-block">Thank you for<br/>your application</h2>
            <h2 className="d-block d-lg-none">Thank you for your application</h2>
            <div className="Text-Body-2 mt-3">We will contact you as soon as possible</div>
            <div className="mt-4 mb-5 pb-5">
              <Button variant="outline-secondary py-3 px-4" size="lg" href="/">Back to Homepage</Button>
            </div>
            <div className="pt-5">
              <FAQs/>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container className="ApplyForm">
      <Row className="justify-content-md-center">
        <Col md={8}>
          <h2>Submit your<br/>application</h2>
          <Form onSubmit={handleSubmit} className="mt-5 pt-5">
            <Form.Group className="mb-4" controlId="jobId">
              <Form.Label>Apply for</Form.Label>
              <Form.Select name="job" value={data.job} onChange={handleChangeData}>
                {!!jobs && jobs.map(item => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))}
              </Form.Select>
              {!!errors?.job?.message && <div className="text-danger my-2" role="alert">{errors?.job?.message}</div>}
            </Form.Group>
            <Form.Group className="mb-4" controlId="name">
              <Form.Label>Full name</Form.Label>
              <Form.Control type="text" name="name" value={data.name} onChange={handleChangeData} />
              {!!errors?.name?.message && <div className="text-danger my-2" role="alert">{errors?.name?.message}</div>}
            </Form.Group>
            <Form.Group className="mb-4" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" name="email" value={data.email} onChange={handleChangeData} />
              {!!errors?.email?.message && <div className="text-danger my-2" role="alert">{errors?.email?.message}</div>}
            </Form.Group>
            <Form.Group className="mb-4" controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control type="text" name="phone" value={data.phone} onChange={handleChangeData} />
              {!!errors?.phone?.message && <div className="text-danger my-2" role="alert">{errors?.phone?.message}</div>}
            </Form.Group>
            <Form.Group className="mb-4" controlId="letter">
              <Form.Label>Cover letter</Form.Label>
              <Form.Control as="textarea" rows={3} name="letter" value={data.letter} onChange={handleChangeData} />
              {!!errors?.letter?.message && <div className="text-danger my-2" role="alert">{errors?.letter?.message}</div>}
            </Form.Group>
            <Form.Group className="mb-4" controlId="upload">
              <Form.Label>Attach CV</Form.Label>
              <div>
                <Button variant="outline-light" className="text-silver btn-control" onClick={() => hiddenFileInput.current.click()}>
                  <i className="fa-solid fa-file-arrow-up me-3"></i>
                  Attach Resume/CV
                  <input
                    ref={hiddenFileInput}
                    id="select-file" type="file" hidden
                    onChange={e => setFileCV(e.target.files[0])}
                    accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                  />
                </Button>
              </div>
              <div className="form-text">Only Word, Excel and PDF file formats are supported</div>
              {!!fileCV && <div className="my-3">{fileCV.name}</div>}
              {!!errors?.cv?.message && <div className="text-danger my-2" role="alert">{errors?.cv?.message}</div>}
            </Form.Group>

            <Button variant="danger" type="submit" disabled={processing} className="rounded-5 w-100 mt-5 bg-red">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ApplyForm;
