import { Col, Container, Row } from "react-bootstrap";

function Support() {
  return (
    <Container className="my-5 py-5 EnRoute">
      <Row className="mt-5 justify-content-md-center">
        <Col xs={12} lg={6}>
          <h2 className="text-center">App Support</h2>
          <div className="text-center Text-Body-2">Encountered a problem while using our app?</div>
          <div className="text-center py-5">
            <a className="btn-outline-secondary btn-lg" href="mailto:support@astraler.com">
              Contact us
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Support;
