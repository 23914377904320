import {
  Container,
  Row,
  Col,
} from 'react-bootstrap'
import photo1Img from '../assets/img/photo1.png'
import photo2Img from '../assets/img/photo2.png'
import photo3Img from '../assets/img/photo3.png'
import photo4Img from '../assets/img/photo4.png'
import photo5Img from '../assets/img/photo5.png'

function Life(props) {
  return (
    <Container className="Life py-5" {...props}>
      <h2 className="text-center">Life at <span className="text-red">Astraler</span></h2>
      <div className="Text-Body-2 text-center pb-4">
        Follow us on
        <a href="https://www.facebook.com/astralertechnology/"><i className="fa-brands fa-facebook ms-3 SocialLink"></i></a>
        <a href="https://www.linkedin.com/company/astraler-technology/"><i className="fa-brands fa-linkedin ms-3 SocialLink"></i></a>
      </div>
      <Row className="mt-5">
        <Col md={4} className="pe-md-4 pe-lg-5 mb-5">
          <img className="w-100" src={photo1Img} alt="Astraler"/>
        </Col>
        <Col md={4} className="p-md-4 p-lg-5 mb-5">
          <img className="w-100 mt-md-5" src={photo2Img} alt="Astraler"/>
        </Col>
        <Col md={4} className="ps-md-4 ps-lg-5 mb-5">
          <img className="w-100" src={photo3Img} alt="Astraler"/>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={5} className="px-md-4 px-lg-5 mb-5">
          <img className="w-100 mt-md-2" src={photo4Img} alt="Astraler"/>
        </Col>
        <Col md={5} className="px-md-4 px-lg-5 mb-5">
          <img className="w-100" src={photo5Img} alt="Astraler"/>
        </Col>
      </Row>
    </Container>
  );
}

export default Life;
