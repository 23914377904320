import { Container } from "react-bootstrap";

function NotFound() {
  return (
    <Container className="my-5 py-5 text-center">
      <h2 className="mt-5 pt-5">404</h2>
      <h3 className="mb-5 pb-5">Page Not Found</h3>
    </Container>
  );
}

export default NotFound;
