import {
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';
import { scrollToElement } from '../ScrollToTop';

function Hero(props) {
  return (
    <Container className="Hero" {...props}>
      <Row>
        <Col md={9}>
          <h2>
            We are a <span className="text-red">local mobile publisher</span> that builds global apps.
          </h2>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={6} className="text-neutrals-3">
          Astraler Technology specializes in researching, developing, and publishing mobile apps on iOS and Android platforms. Astraler pioneers to position itself as a professional mobile app studio among software companies in Da Nang, Viet Nam, with a vision to be among the top app publishers in South East Asia.
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <LinkContainer to="/#jobs">
            <Button variant="outline-secondary" size="lg" onClick={() => scrollToElement('jobs')}>Join Us</Button>
          </LinkContainer>
        </Col>
      </Row>
    </Container>
  );
}

export default Hero;
