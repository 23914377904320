import Benefits from './components/Benefits';
import Life from './components/Life';
import JobDetails from './components/JobDetails';
import { useParams } from 'react-router-dom';

function Job() {
  const { id } = useParams()
  return (
    <>
      <JobDetails jobId={id}/>
      <Benefits id="benefits"/>
      <Life/>
    </>
  );
}

export default Job;
