import { Col, Container, Row } from "react-bootstrap";

function EnRoute() {
  return (
    <Container className="my-5 py-5 EnRoute">
      <Row className="mt-5 justify-content-md-center">
        <Col xs={12} lg={6}>
          <h2 className="text-center">EnRoute App Support</h2>
          <div className="text-center Text-Body-2">Encountered a problem while using our app?</div>
          <div className="text-center py-5">
            <a className="btn-outline-secondary btn-lg" href="mailto:support@astraler.com">
              Contact us
            </a>
          </div>
          <div className="pt-5">
            <h3 className="Text-Body-1">FAQs</h3>
            <p className="Text-Body-2-Bold mt-4 d-flex justify-content-between">
              <span>Does EnRoute work in my area</span>
              <i class="fa-solid fa-angle-right"></i>
            </p>
            <p>enRoute gets location data from Google Maps. That means it works in every region that's supported by Google Maps.</p>
            <p>If you live in a remote area, it might happen that addresses are in a slightly different location. If this occurs, you can fix this issue for yourself and other drivers, by correcting it on Google Maps. This only takes a few minutes.
            </p>
          </div>
          <div className="py-5"><hr/></div>
        </Col>
      </Row>
    </Container>
  );
}

export default EnRoute;
