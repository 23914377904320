import { useEffect, useState } from 'react'
import { get } from '../api'
import FaqItem from './FaqItem'

function FAQs({ jobId }) {
  const [faqs, setFaqs] = useState([])

  useEffect(() => {
    async function fetchDataFaq() {
      const { data } = await get(`/api/v1/faqs?limit=1000&sort=-priority`)
      setFaqs(data)
    }
    fetchDataFaq()
  }, [jobId])

  return !!faqs.length && (
    <>
      <div className="Text-Body-1 mt-5 mb-3">FAQs</div>
      {faqs.map(item => (
        <div key={item._id}>
          <FaqItem {...item} />
          <hr/>
        </div>
      ))}
    </>
  );
}

export default FAQs;
