import { useState } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";

function Privacy() {
  const [activeTab, setActiveTab] = useState('main')
  return (
    <Container className="my-5 py-5 Terms">
      <Row className="mt-5">
        <Col xs={12} lg={4}>
          <h2 className="cursor-pointer" onClick={() => setActiveTab('main')}>Privacy Policy</h2>
          <Nav className="flex-column">
            <Nav.Link className="px-0" active={activeTab === 'menu1'} onClick={() => setActiveTab('menu1')}>User Consent</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu2'} onClick={() => setActiveTab('menu2')}>A Note About Children</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu3'} onClick={() => setActiveTab('menu3')}>A Note to Users Outside of the United States</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu4'} onClick={() => setActiveTab('menu4')}>Types of Data We Collect</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu5'} onClick={() => setActiveTab('menu5')}>Use of Your Personal Data</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu6'} onClick={() => setActiveTab('menu6')}>Disclosure of Your Personal Data</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu7'} onClick={() => setActiveTab('menu7')}>Third Party Data Collection</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu8'} onClick={() => setActiveTab('menu8')}>Your Choices Regarding Your Personal Data</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu9'} onClick={() => setActiveTab('menu9')}>Security of Your Personal Data</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu10'} onClick={() => setActiveTab('menu10')}>Enforcement</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu11'} onClick={() => setActiveTab('menu11')}>Dispute Resolution</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu12'} onClick={() => setActiveTab('menu12')}>Contact Information</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu13'} onClick={() => setActiveTab('menu13')}>Changes to This Privacy Policy</Nav.Link>
          </Nav>
        </Col>
        <Col xs={12} lg={8}>
          {activeTab === 'main' && <div id="main">
            <label>Last Updated: Oct 22nd, 2018</label>
            <br/>
            <p>
            Astraler is committed to protecting your privacy. We have prepared this Privacy Policy to
            describe to you our practices regarding the Personal Data (as defined below) we collect from
            users of our site, located at www.astraler.com ("Site") and mobile application ("App") and
            our Service (as defined in the Terms of Service). Capitalized terms not defined in this Privacy
            Policy have the meanings given in our <a href="/terms">Terms of Service</a>.
            </p>
          </div>}

          {activeTab === 'menu1' && <div id="menu1" >
          <p>By submitting Personal Data through our Site, App, or Service, you agree to the terms of this
          Privacy Policy and you expressly consent to the collection, use and disclosure of your
          Personal Data in accordance with this Privacy Policy.
          </p>
          </div>}
          {activeTab === 'menu2' && <div id="menu2" >
          <p>We do not intentionally gather Personal Data from visitors who are under the age of 13.</p>
          </div>}
          {activeTab === 'menu3' && <div id="menu3" >
          <p>Your Personal Data may be transferred to and processed in the United States. With respect to
          our collection and processing of Personal Data about individuals in the European Union, we
          comply with the United States/European Union Safe Harbor Framework as set forth by the U.S.
          Department of Commerce (“Safe Harbor”) and have certified our adherence to the U.S./EU Safe
          Harbor Privacy Principles (the “Safe Harbor Principles). For more information regarding Safe
          Harbor, please visit the United States Department of Commerce Safe Harbor website at
          <a href="https://www.export.gov/safeharbor">http://www.export.gov/safeharbor</a>.
          </p>
          </div>}
          {activeTab === 'menu4' && <div id="menu4" >
          <p>"Personal Data" means data that allows someone to identify or contact you, including, for
          example, your name, address, telephone number, e-mail address, as well as any other
          non-public information about you that is associated with or linked to any of the foregoing
          data. “Anonymous Data” means data that is not associated with or linked to your Personal
          Data; Anonymous Data does not, by itself, permit the identification of individual persons.
          We collect Personal Data and Anonymous Data, as described below.</p>
          <b>(a) Information You Provide to Us</b>
          <ul>
          <li><p>We may collect Personal Data from you, such as your e-mail address, and password when
          you create an account to log in to our network ("Account").</p>
          </li>
          <li><p>When you order the Service on our Site or App, we will collect all information
          necessary
          to complete the transaction, including your name, payment information, billing
          information and shipping information.</p>
          </li>
          <li><p>We retain information on your behalf, such as files and messages that you store using
          your Account.</p>
          </li>
          <li><p>If you provide us feedback or contact us via e-mail, we will collect your name and
          email address, as well as any other content included in the email, in order to send you
          a reply.</p></li>
          <li><p>When you participate in one of our surveys, we may collect additional profile
          information.</p></li>
          <li><p>When you post messages on the help forums or message boards of our Site, the
          information contained in your posting will be stored on our servers and other users will
          be able to see it.</p></li>
          <li><p>We also collect other types of Personal Data that you provide to us voluntarily, such
          as your operating system and version, product registration number, and other requested
          information if you contact us via e-mail regarding support for the Service.</p></li>
          <li><p>We may also collect Personal Data, such as at other points in our Site or App that
          state that Personal Data is being collected.</p></li>
          </ul>
          <b>(b) Information Collected via Technology.</b>
          <ul>
          <li><p>Our servers (which may be hosted by a third party service provider) collect
          information from you, including your mobile device type, application version, browser
          type, computer or mobile operating system, Internet Protocol (IP) address (a number that
          is automatically assigned to your computer or mobile device when you use the Internet,
          which may vary from session to session), domain name, information about the way you use
          the application and/or a date/time stamp for your visit of the Site or use of the App or
          Service.</p></li>
          <li><p>We also use cookies, URL information and mobile analytics tools to gather information
          regarding the date and time of your visit of the Site or use of the App and the
          information for which you searched and which you viewed. “Cookies” are small pieces of
          information that a website sends to your device’s storage while you are viewing a web
          site or using our App. We may use both session Cookies (which expire once you close your
          web browser) and persistent Cookies (which stay on your device until you delete them) to
          provide you with a more personal and interactive experience on our Site and App.
          Persistent Cookies can be removed by following Internet browser help file directions or
          by clearing application specific information on your mobile device.</p></li>
          </ul>
          <b>(c) Information Collected from You About Others.</b>
          <p>If you decide to invite a third party to create an Account, we will collect your and the
          third party’s names and email addresses in order to send an email and follow up with the
          third party. You or the third party may contact us at dev@astraler.com to request
          the removal of this information from our database.</p>
          <b>(d) Information Collected from Social Networking Sites.</b>
          <p>If you log on to the Service with your login credentials from a social networking site (e.g.,
          Facebook or Twitter), we may receive Personal and/or Anonymous Data about you from such
          social networking site, in accordance with the terms of use and privacy policy of the third
          party provider of such social networking site. For example, Facebook may share your friend
          list with us, in order to help us establish your Account and find other Service users that
          you know. We may add this information to the information we have already collected from you
          via the Service.</p>
          </div>}
          {activeTab === 'menu5' && <div id="menu5" >
          <p>In general, Personal Data you submit to us is used either to respond to requests that you
          make, or to aid us in serving you better. We will only use Personal Data in ways that are
          compatible with the purposes for which it was collected or as subsequently authorized by
          you. We use your Personal Data in the following ways:</p>
          <ul>
          <li><p>to facilitate the creation of and secure your Account on our network;</p></li>
          <li><p>identify you as a user in our system;</p></li>
          <li><p>provide improved administration of our Site, App and Service;</p></li>
          <li><p>provide the services you request;</p></li>
          <li><p>improve the quality of experience when you interact with our Site, App and
          Service;</p></li>
          <li><p>send you a welcome email to verify ownership of the email address provided when your
          Account was created;</p></li>
          <li><p>send you administrative e-mail notifications, such as security or support and
          maintenance advisories;</p></li>
          <li><p>respond to your inquiries related to employment opportunities or other requests;</p>
          </li>
          <li><p>to send newsletters, surveys, offers, and other promotional materials related to our
          Service and for our other marketing purposes.</p></li>
          </ul>
          </div>}
          {activeTab === 'menu6' && <div id="menu6" >
          <p>We disclose your Personal Data as described below and as described elsewhere in this Privacy
          Policy.</p>
          <b>(a) Third Party Service Providers</b>
          <p>We may share your Personal Data with third party service providers to: provide you with the
          Service that we offer you through our Site or App; to conduct quality assurance testing; to
          facilitate creation of accounts; to provide technical support; and/or to provide us with
          other services. These third party service providers are required not to use your Personal
          Data other than to provide the services we request. We require our third party service
          providers that have access to any Personal Data that you provide from the European Economic
          Area to provide at least the same level of privacy protection as is required by the
          applicable Safe Harbor Principles. Where we have knowledge that a service provider is using
          or disclosing Personal Data in a manner contrary to this Privacy Policy, we will take
          reasonable steps to prevent or stop the use or disclosure of such Personal Data.</p>
          <b>(b) Affiliates and Acquisitions.</b>
          <p>We may share some or all of your Personal Data with our parent company, subsidiaries, joint
          ventures, or other companies under a common control (“Affiliates”), in which case we will
          require our Affiliates to honor this Privacy Policy. If another company acquires our
          company, business, or our assets, that company will possess the Personal Data collected by
          us and will assume the rights and obligations regarding your Personal Data as described in
          this Privacy Policy. In the event of an insolvency, bankruptcy or receivership, your
          Personal Data may also be transferred on a standalone basis.</p>
          <b>(c) Other Disclosures.</b>
          <p>Regardless of any choices you make regarding your Personal Data (as described below), we may
          disclose Personal Data if we believe in good faith that such disclosure is necessary (a) in
          connection with any legal investigation; (b) to comply with relevant laws or to respond to
          subpoenas or warrants served on Astraler; (c) to protect or defend the rights or property
          of Astraler or users of the Service; and/or (d) to investigate or assist in preventing any
          violation or potential violation of the law, this Privacy Policy, or Terms of Service.</p>
          </div>}
          {activeTab === 'menu7' && <div id="menu7" >
          <b>(a) Information Disclosed to Analytics Companies and Ad Networks.</b>
          <p>We may work with analytics companies to help us understand how our Site, our Service and our
          App are being used, such as the frequency and duration of usage. We may also use third-party
          advertising companies to serve ads when you visit our Site or App. These companies may use
          some of the information collected by the Site or the App, including the unique
          identification ID of your mobile device. To protect the anonymity of this information, we
          limit data sharing to ensure these companies can’t identify you personally based on the
          information we provide. These companies may also obtain information about other applications
          you have downloaded to your mobile device, the mobile websites you visit, your non-precise
          location information (e.g., you zip code) and other non-precise location information in
          order to help analyze and service anonymous targeted advertising on the Site and App and
          elsewhere.</p>
          <b>(b) Tracking Cookies.</b>
          <p>Some of the analytics companies and third party advertising companies used on this Site may
          place "tracking cookies" on your computer. If you would like more information about this
          practice or to opt-out of having this information used by third-party ad servers that are
          part of the Network Advertising Initiative ("NAI") or the Interactive Advertising Bureau
          ("IAB"), please visit:NAI: <a href="https://www.networkadvertising.org/managing/opt_out.aspIAB:">http://www.networkadvertising.org/managing/opt_out.aspIAB:</a>
          <a href="http://www.youronlinechoices.com/opt-out">http://www.youronlinechoices.com/opt-out</a>
          </p>
          <b>(c) Third Party Websites.</b>
          <p>When you click on a link to any other website or location, you will leave our Site and go to
          another site and another entity may collect Personal Data or Anonymous Data from you. We
          have no control over, do not review, and cannot be responsible for, these outside websites
          or their content. Please be aware that the terms of this Privacy Policy do not apply to
          these outside websites or content, or to any collection of data after you click on links to
          such outside websites.</p>
          </div>}
          {activeTab === 'menu8' && <div id="menu8" >
          <b>(a) Choices.</b>
          <p>We offer you choices regarding the collection, use, and sharing of your Personal Data.</p>
          <ul>
          <li>
          <p>We may periodically send you free newsletters and e-mails that directly promote the
          use of our Site, App or Service. When you receive newsletters or promotional
          communications from us, you may indicate a preference to stop receiving further
          communications from us and you will have the opportunity to “opt-out” by following
          the unsubscribe instructions provided in the e-mail you receive or by contacting us
          directly (please see contact information below).</p>
          </li>
          <li>
          <p>Despite your indicated email preferences, we may send you service related
          communication, including notices of any updates to our Terms of Service or Privacy
          Policy.</p>
          </li>
          </ul>
          <b>(b) Changes to Personal Data.</b>
          <p>You may change any of your Personal Data in your Account by editing your profile within your
          Account. You may request deletion of your Personal Data by us, but please note that we may
          be required to keep this information and not delete it (or to keep this information for a
          certain time, in which case we will comply with your deletion request only after we have
          fulfilled such requirements). When we delete any information, it will be deleted from the
          active database, but may remain in our archives. We may retain your information for fraud or
          similar purposes.</p>
          </div>}
          {activeTab === 'menu9' && <div id="menu9" >
          <p>We are committed to protecting the security of your Personal Data. We use a variety of
          industry-standard security technologies and procedures to help protect your Personal Data
          from unauthorized access, use, or disclosure. We also require you to enter a password to
          access your Account information. Please do not disclose your Account password to
          unauthorized people. No method of transmission over the Internet, or method of electronic
          storage, is 100% secure, however. Therefore, while we use reasonable efforts to protect your
          Personal Data, we cannot guarantee its absolute security.</p>
          </div>}
          {activeTab === 'menu10' && <div id="menu10">
          <p>We conduct periodic self-assessments to verify that we comply with the Safe Harbor
          Principles, that the attestations and assertions we make about our privacy practices are
          true, and that such privacy practices have been implemented as presented in this Privacy
          Policy. We will take reasonable steps to remedy any non-compliance with the Safe Harbor
          Principles found as a result of any self-assessment.</p>
          </div>}
          {activeTab === 'menu11' && <div id="menu11">
          <p>If you have any complaints regarding our adherence to this Privacy Policy or our compliance
          with the Safe Harbor program, please first contact us by email at
          dev@astraler.com. We will investigate and attempt to resolve any such
          complaints in accordance with the principles contained in this Privacy Policy. If the
          complaint cannot be or is not adequately resolved through our internal dispute resolution
          process, you may submit your complaint to the American Arbitration Association (“AAA”).
          Unless otherwise agreed upon by the parties in writing, the arbitration will be governed by
          the AAA’s Commercial Arbitration Rules and, if the arbitrator deems them applicable, the
          Supplementary Procedures for Consumer Related Disputes. For more information on AAA, its
          Rules and Procedures, and how to file an arbitration claim, you may call AAA at 800-778-7879
          or visit the AAA website at <a href="http://www.adr.org">http://www.adr.org</a></p>
          </div>}
          {activeTab === 'menu12' && <div id="menu12">
          <p>We welcome your comments or questions regarding this Privacy Policy. Please e-mail us at
          dev@astraler.com</p>
          </div>}
          {activeTab === 'menu13' && <div id="menu13">
          <p>This Privacy Policy is subject to occasional revision, and if we make any material changes in
          the way we use your Personal Data, we will notify you by sending you an e-mail to the last
          e-mail address you provided to us and/or by prominently posting notice of the changes on our
          Site. Any changes to this Privacy Policy will be effective upon the earlier of thirty (30)
          calendar days following our dispatch of an e-mail notice to you or thirty (30) calendar days
          following our posting of notice of the changes on our Site. These changes will be effective
          immediately for new users of our Service. Please note that at all times you are responsible
          for updating your Personal Data to provide us with your most current e-mail address. In the
          event that the last e-mail address that you have provided us is not valid, or for any reason
          is not capable of delivering to you the notice described above, our dispatch of the e-mail
          containing such notice will nonetheless constitute effective notice of the changes described
          in the notice. In any event, changes to this Privacy Policy may affect our use of Personal
          Data that you provided us prior to our notification to you of the changes. If you do not
          wish to permit changes in our use of your Personal Data, you must notify us prior to the
          effective date of the changes that you wish to deactivate your Account with us. Continued
          use of our Site, App or Service, following notice of such changes shall indicate your
          acknowledgement of such changes and agreement to be bound by the terms and conditions of
          such changes.</p>
          </div>}
        </Col>
      </Row>
    </Container>
  );
}

export default Privacy;
