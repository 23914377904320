import { useState } from "react";
import { Collapse } from "react-bootstrap";

function FaqItem({question, answer}) {
  const [open, setOpen] = useState(false)

  return (
    <div className="my-5">
      <div
        className="d-flex justify-content-between cursor-pointer"
        onClick={() => setOpen(!open)}
        aria-controls="collapse-text"
        aria-expanded={open}
      >
        <div className="Text-Body-2-Bold">{question}</div>
        {!open && <i className="fa-solid fa-chevron-down fs-10"></i>}
        {!!open && <i className="fa-solid fa-chevron-right fs-10"></i>}
      </div>
      <Collapse in={open}>
        <div className="Text-Body-2 mt-4">{answer}</div>
      </Collapse>
    </div>
  );
}

export default FaqItem;
