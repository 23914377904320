import { useState } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";

function Terms() {
  const [activeTab, setActiveTab] = useState('main')
  return (
    <Container className="my-5 py-5 Terms">
      <Row className="mt-5">
        <Col xs={12} lg={4}>
          <h2 className="cursor-pointer" onClick={() => setActiveTab('main')}>Terms</h2>
          <Nav className="flex-column">
            <Nav.Link className="px-0" active={activeTab === 'menu1'} onClick={() => setActiveTab('menu1')}>Astraler Service and Properties</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu3'} onClick={() => setActiveTab('menu3')}>Registration</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu4'} onClick={() => setActiveTab('menu4')}>Responsibility for Content</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu5'} onClick={() => setActiveTab('menu5')}>User Conduct</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu6'} onClick={() => setActiveTab('menu6')}>Fees and Purchase Terms</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu7'} onClick={() => setActiveTab('menu7')}>Astraler Is Provided As-Is</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu8'} onClick={() => setActiveTab('menu8')}>Limitation of Liability</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu9'} onClick={() => setActiveTab('menu9')}>Remedies</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu10'} onClick={() => setActiveTab('menu10')}>Miscellaneous Legal Terms</Nav.Link>
            <Nav.Link className="px-0" active={activeTab === 'menu11'} onClick={() => setActiveTab('menu11')}>International Provisions</Nav.Link>
          </Nav>
        </Col>
        <Col xs={12} lg={8}>
          {activeTab === 'main' && <div id="main">
            <label>Last Updated: Oct 22nd, 2018</label>
            <br/>
            <p>Please read this Terms of Service agreement (the “Terms”) carefully. By accessing or using this
            website
            (the “Website”) in any way, including using the services and resources available or enabled via
            the
            Website or application (the “Service”), downloading the Astraler software application for
            mobile
            devices (the “Application”), completing the registration process, and/or merely browsing the
            Website,
            you represent that (1) you have read, understand, and agree to be bound by the Terms, (2) you
            are of
            legal age to form a binding contract with Astraler, and (3) you have the authority to enter
            into
            the
            Terms personally or on behalf of the company you have named as the customer, and to bind that
            company to
            the Terms. The term “you” refers to the individual or legal entity, as applicable, identified as
            the
            customer when you registered on the Website. If you do not agree to be bound by the terms, you
            may
            not
            access or use this Website or the Service.
            </p>
            <p>If you subscribe to the Services for a term (the “Initial Term”), then the Terms will be
            automatically
            renewed for additional periods of the same duration as the initial term at Astraler’s
            then-current
            fee
            for such services unless you decline to renew your subscription in accordance with section 6.5
            below.
            </p>
            <p>
            The terms of use require the the use of arbitration on an individual basis to resolve disputes,
            rather
            than jury trials or class actions, and also limit the remedies available to you in the event of
            a
            dispute.
            </p>
            <p>
            Please note that the terms are subject to change by Astraler in its sole discretion at any
            time. We
            will update the "Last Updated" date at the top of the Terms. If we make any material changes,
            and
            you
            have registered to use the Service, we will also send an e-mail to you at the last e-mail
            address
            you
            provided to us pursuant to the Terms. Any changes to the Terms will be effective immediately for
            new
            users of the Website or Service and will be effective thirty (30) days after posting of notice
            of
            such
            changes on the Website for existing users, provided that any material changes shall be effective
            for
            users who have a registered account on the Website (each, a "Registered User") upon the earlier
            of
            thirty (30) days after posting of notice of such changes on the Website or thirty (30) days
            after
            dispatch of an e-mail notice of such changes to Registered Users. Astraler may require you to
            provide
            consent to the updated Agreement in a manner specified before further use of the Website or the
            Service
            is permitted. If you do not agree to any change(s) after receiving a notice of such change(s),
            you
            shall
            stop using the Website and/or the Service. Otherwise, your continued use of the Website and/or
            Service
            constitutes your acceptance of such change(s). <b> PLEASE REGULARLY CHECK THE WEBSITE TO VIEW
            THE
            THEN-CURRENT AGREEMENT.</b>
            </p>
          </div>}

          {activeTab === 'menu1' && <div id="menu1">
            <p>The Astraler Service is a set of tools, applications, web services and widgets that allows
            you
            to
            capture, control and manage data on your electronic devices.</p>
            <p>Astraler and its licensors own all rights, title, and interest in the Application, the
            Software,
            the
            Website, the Service, and the information and content available on or through the foregoing
            (collectively, the “Astraler Properties”). The Astraler Properties are protected by
            copyright
            laws
            throughout the world. Subject to the Terms, Astraler grants you a limited license to
            reproduce
            portions of the Astraler Properties for the sole purpose of using the Astraler Properties
            for
            your
            personal, non-commercial purposes. Unless otherwise specified by Astraler in a separate
            license,
            your right to use any Astraler Properties is subject to the Terms. Astraler and its
            licensors
            reserve all rights not granted in these Terms.</p>
            <b>Application License</b>
            <p>Application License. Subject to your compliance with the Terms, Astraler grants you a
            limited, non-exclusive, non-transferable, non-sublicensable, revocable license to download,
            install and use a copy of the Application on a single mobile device or computer that you own
            or control and to run such copy of the Application solely for your own personal use.
            Furthermore, with respect to any Application accessed through or downloaded from the Apple
            App Store (an “App Store Sourced Application”), you will only use the App Store Sourced
            Application (i) on an Apple-branded product that runs the iOS (Apple’s proprietary operating
            system) and (ii) as permitted by the “Usage Rules” set forth in the Apple App Store Terms of
            Service.</p>
            <b>Astraler Software</b>
            <p>Use of any software and associated documentation, other than the Application, that is made
            available via the Website or the Service (“Software”) is governed by the Terms. Any copying
            or redistribution of the Software is prohibited, including any copying or redistribution of
            the Software to any other server or location, redistribution or use on a service bureau
            basis. If the Software is a pre-release version, then you are not permitted to use or
            otherwise rely on the Software for any commercial or production purposes. Astraler grants
            you a non-assignable, non-transferable, non- sublicensable, revocable non-exclusive license
            to use the Software for the sole purpose of enabling you to use the Service in the manner
            permitted by the Terms. Some Software may be offered under an open source license that we
            will make available to you. There may be provisions in the open source license that
            expressly override some of the Terms.</p>
            <b>Updates</b>
            <p>You understand that the Astraler Properties are evolving. As a result, Astraler may require
            you to accept updates to the Application or the Software that you have installed on your
            computer or mobile device. You acknowledge and agree that Astraler may update the Astraler
            Properties with or without notifying you. Any updates to the Astraler Properties are
            subject to these Terms. You may need to update third party software from time to time in
            order to continue using the Astraler Properties.</p>
            <b>Certain Restrictions</b>
            <p>The rights granted to you in the Terms are subject to the following restrictions: (a) you
            shall not license, sell, rent, lease, transfer, assign, reproduce, distribute, host or
            otherwise commercially exploit the Astraler Properties, (b) you shall not frame or utilize
            framing techniques to enclose any Astraler trademark or logo (including images, text, page
            layout or form); (c) you shall not use any metatags or other “hidden text” using Astraler’s
            name or trademarks; (d) you shall not modify, translate, adapt, merge, make derivative works
            of, disassemble, decompile, reverse compile or reverse engineer any part of the
            Astraler</p>
            <p>
            Properties except to the extent the foregoing restrictions are expressly prohibited by
            applicable law; (e) you shall not attempt or engage in, any potentially harmful acts that
            are directed against the Astraler Properties, including but not limited to violating or
            attempting to violate any security features of the Astraler Properties, using any manual or
            automated software, devices or other processes (including but not limited to spiders,
            robots, scrapers, crawlers, avatars, data mining tools or the like) to “scrape” or download
            data from any Astraler Properties, or introducing viruses, worms, or similar harmful code
            into the Astraler Properties; (f) access the Astraler Properties in order to build a
            similar or competitive website, application or service; (g) except as expressly stated
            herein, no part of the Astraler Properties may be copied, reproduced, distributed,
            republished, downloaded, displayed, posted or transmitted in any form or by any means; (h)
            you shall not remove or destroy any copyright notices or other proprietary markings
            contained on or in the Astraler Properties; (i) you shall not use the Astraler Properties
            in any manner that could damage, disable, overburden, or impair Astraler’s systems or
            networks, or interfere with any other party’s use and enjoyment of the Astraler Properties,
            including without limitation, by means of overloading, “flooding,” “spamming,” “mail
            bombing”, or “crashing” the Astraler Properties; (j) you may not attempt to gain
            unauthorized access to any computer systems or networks associated with the Astraler
            Properties; (k) . Any future release, update or other addition to the Astraler Properties
            shall be subject to the Terms. Astraler, its suppliers and service providers reserve all
            rights not granted in the Terms. Any unauthorized use of the Astraler Properties terminates
            the licenses granted by Astraler pursuant to the Terms.</p>
            <b>Third Party Materials</b>
            <p>Third Party Materials. As a part of the Astraler Properties, you may have access to
            materials that are hosted by another party. You agree that it is impossible for Astraler to
            monitor such materials and that you access these materials at your own risk.</p>
          </div>}

          {activeTab === 'menu3' && <div id="menu3">
            <b>Registering Your Account</b>
            <p>In order to access certain features of the Astraler Properties, you may be required to
            become a Registered User. For purposes of the Terms, a “Registered User” is a user who has
            registered an account on the Application (“Account”).</p>
            <b>Registration Data</b>
            <p>In registering for the Service, you agree to (1) provide true, accurate, current and complete
            information about yourself as prompted by the Service’s registration form (the “Registration
            Data”); and (2) maintain and promptly update the Registration Data to keep it true,
            accurate, current and complete. You represent that you are (1) at least thirteen (13) years
            old; (2) of legal age to form a binding contract; and (3) not a person barred from using the
            Service under the laws of the United States, your place of residence or any other applicable
            jurisdiction. You agree that you shall monitor your Account to restrict use by minors, and
            you will accept full responsibility for any unauthorized use of the Astraler Properties by
            minors. If you provide any information that is untrue, inaccurate, not current or
            incomplete, or Astraler has reasonable grounds to suspect that such information is untrue,
            inaccurate, not current or incomplete, Astraler has the right to suspend or terminate your
            Account and refuse any and all current or future use of the Astraler Properties (or any
            portion thereof). You agree not to create an Account using a false identity or information,
            or on behalf of someone other than yourself. You agree that you shall not have more than one
            Account per platform at any given time. Astraler reserves the right to remove or reclaim
            any usernames at any time and for any reason, including but not limited to, claims by a
            third party that a username violates the third party’s rights. You agree not to create an
            Account or use the Astraler Properties if you have been previously removed by Astraler, or
            if you have been previously banned from the Service.</p>
            <b>Activities Under Your Account</b>
            <p>You are responsible for all activities that occur under your Account. You may not share your
            Account or password with anyone, and you agree to (1) notify Astraler immediately of any
            unauthorized use of your password or any other breach of security; and (2) exit from your
            Account at the end of each session.</p>
            <b>Necessary Equipment and Software</b>
            <p>You must provide all equipment and software necessary to connect to the Astraler Properties,
            including but not limited to, a mobile device that is suitable to connect with and use the
            Astraler Properties, in cases where the Service offer a mobile component. You are solely
            responsible for any fees, including Internet connection or mobile fees, that you incur when
            accessing the Astraler Properties.</p>
          </div>}

          {activeTab === 'menu4' && <div id="menu4">
          <b>Types of Content</b>
          <p>You acknowledge that all information, data, text, software, music, sound, photographs,
          graphics, video, messages, tags and/or other materials accessible through the Astraler
          Properties, whether publicly posted or privately transmitted (“Content”), are the sole
          responsibility of the party from whom such Content originated. This means that you, and not
          Astraler, are entirely responsible for all Content that you upload, post, e-mail, transmit
          or otherwise make available (“Make Available”) through Astraler Properties (“Your
          Content”).</p>
          </div>}

          {activeTab === 'menu5' && <div id="menu5">
          <p>As a condition of use, you agree not to use the Astraler Properties for any purpose that is
          prohibited by the Terms or by applicable law. You shall not (and shall not permit any third
          party) either (a) to take any action or (b) Make Available any Content on or through the
          Website and the Service that: (i) infringes or violates any patent, trademark, trade secret,
          copyright, contractual right, right of publicity or other right of any person or entity;
          (ii) violates any acceptable use or other information technology policy that may apply to
          your use of any computer system or network; (iii) is unlawful, threatening, abusive,
          harassing, defamatory, libelous, deceptive, fraudulent, invasive of another’s privacy,
          tortious, obscene, offensive, or profane; (iv) constitutes unauthorized or unsolicited
          advertising, junk or bulk e-mail; (v) involves commercial activities and/or sales without
          Astraler’s prior written consent, such as contests, sweepstakes, barter, advertising, or
          pyramid schemes; (vi) impersonates any person or entity, including any employee or
          representative of Astraler; or (vii) is inappropriate in any other manner that Astraler
          determines in its sole, reasonable discretion.</p>
          </div>}

          {activeTab === 'menu6' && <div id="menu6">
          <b>General Purpose of Terms: Sale of Service, not Software</b>
          <p>The purpose of the Terms is for you to secure access to the Services. All fees set forth
          within and paid by you under the Terms shall be considered solely in furtherance of this
          purpose. In no way are these fees paid considered payment for the sale, license, or use of
          Astraler’s Software or Application, and, furthermore, any use of Astraler's Software or
          Application by you in furtherance of the Terms will be considered merely in support of the
          purpose of the Terms.</p>
          <b>Payment</b>
          <p>You agree to pay all fees or charges to your Account in accordance with the fees, charges,
          and billing terms in effect at the time a fee or charge is due and payable. Astraler
          collects payments through a limited number of payment services (“Payment Provider”). You
          must provide Astraler with valid account information for the respective chosen Payment
          Provider. Your Payment Provider agreement governs your use of the designated credit card or
          other payment method, and you must refer to that agreement and not the Terms to determine
          your rights and liabilities. By providing Astraler with your payment information, you agree
          that Astraler is authorized to immediately invoice your Account for all fees and charges
          due and payable to Astraler hereunder and that no additional notice or consent is required.
          You agree to immediately notify Astraler of any change in payment credentials for payment
          hereunder. Astraler reserves the right at any time to change its prices and billing
          methods, either immediately upon posting on the Website or by e-mail delivery to you.</p>
          <b>Service Subscription Fees</b>
          <p>You will be responsible for payment of the applicable fee for any Services (each, a “Service
          Subscription Fee”) at the time you create your Account and select the term of your
          subscription (each, a “Service Commencement Date”). Except as set forth in the Terms, all
          fees for the Services are non-refundable. No contract will exist between you and Astraler
          for the Services until Astraler or the Payment Provider, for example Apple or Google,
          accepts your order by a confirmatory e-mail, SMS/MMS message, or other appropriate means of
          communication.</p>
          <b>Taxes</b>
          <p>Astraler’s fees are net of any applicable Sales Tax. If any Service, or payments for any
          Service, are subject to Sales Tax in any jurisdiction and you have not remitted the
          applicable Sales Tax to Astraler, you will be responsible for the payment of such Sales Tax
          and any related penalties or interest to the relevant tax authority and you will indemnify
          Astraler for any liability or expense we may incur in connection with such Sales Taxes.
          Upon our request, you will provide us with official receipts issued by the appropriate
          taxing authority, or such other evidence that you have paid all applicable taxes. For
          purposes of this section, “Sales Tax” shall mean any sales or use tax, and any other tax
          measured by sales proceeds, that Astraler its permitted to pass to its customers that is
          the functional equivalent of a sales tax where the applicable taxing jurisdiction does not
          otherwise impose a sales or use tax.</p>
          <b>Automatic Renewal</b>
          <p>Your subscription will continue indefinitely until terminated in accordance with the Terms.
          After your initial subscription period, and again after any subsequent subscription period,
          your subscription will automatically commence on the first day following the end of such
          period (each a “Renewal Commencement Date”) and continue for an additional equivalent
          period, at Astraler's then-current price for such subscription. You agree that your Account
          will be subject to this automatic renewal feature unless you cancel your subscription at
          least 24 hours prior to the Renewal Commencement Date by logging into and going to the
          "Manage App Subscriptions” page in the Apple App Store or the “My Apps” page in the Google
          Play Store app. The same page will permit you to change your Account settings if you do not
          wish your subscription to renew automatically, or if you want to change or terminate your
          subscription. If you cancel your subscription, you may use your subscription until the end
          of your then-current subscription term; your subscription will not be renewed after your
          then-current term expires. However, you will not be eligible for a prorated refund of any
          portion of the subscription fee paid for the then- current subscription period. By
          subscribing, you authorize Astraler to charge your Payment Provider now, and again at the
          beginning of any subsequent subscription period. Upon renewal of your subscription, if
          Astraler does not receive payment from your Payment Provider, (i) you agree to pay all
          amounts due on your Account upon demand, and/or (ii) you agree that Astraler may either
          terminate or suspend your subscription and continue to attempt to charge your Payment
          Provider until payment is received (upon receipt of payment, your Account will be
          re-activated and for purposes of automatic renewal, your new subscription commitment period
          will begin as of the day payment was received).</p>
          </div>}

          {activeTab === 'menu7' && <div id="menu7" className="text-lowercase">
          <p>Astraler CANNOT GUARANTEE THAT YOUR CONTENT WILL BE SAFE FROM OUTSIDE ATTACKS, HACKERS OR
          OTHER WAYS OF ACCESSING YOUR CONTENT ON THE FILE SYSTEMS. YOU EXPRESSLY UNDERSTAND AND AGREE
          THAT TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR USE OF THE Astraler PROPERTIES IS AT
          YOUR SOLE RISK, AND THE Astraler PROPERTIES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
          BASIS, WITH ALL FAULTS. Astraler EXPRESSLY DISCLAIMS ALL WARRANTIES, REPRESENTATIONS, AND
          CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
          IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT.</p>
          </div>}

          {activeTab === 'menu8' && <div id="menu8" className="text-lowercase">
          <p>YOU UNDERSTAND AND AGREE THAT IN NO EVENT SHALL Astraler BE LIABLE FOR ANY INDIRECT,
          INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH
          THE WEBSITE, THE APPLICATION, THE SOFTWARE, THE SERVICE, INCLUDING WITHOUT LIMITATION, ANY
          DAMAGES RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER OR NOT Astraler HAD BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. UNDER NO CIRCUMSTANCES WILL Astraler BE LIABLE
          TO YOU FOR MORE THAN THE AMOUNT RECEIVED BY Astraler AS A RESULT OF YOUR USE OF THE SERVICE
          DURING THE TWELVE-MONTH PERIOD PRECEDING THE DATE ON WHICH YOU FIRST ASSERT YOU CLAIM. IF
          YOU HAVE NOT PAID Astraler ANY AMOUNTS DURING SUCH PERIOD, Astraler'S SOLE AND EXCLUSIVE
          LIABILITY SHALL BE LIMITED TO FIFTY DOLLARS ($50.00).</p>
          </div>}

          {activeTab === 'menu9' && <div id="menu9">
          <b>Violations</b>
          <p>If Astraler becomes aware of any possible violations by you of the Terms, Astraler reserves
          the right to investigate such violations. If, as a result of the investigation, Astraler
          believes that criminal activity has occurred, Astraler reserves the right to refer the
          matter to, and to cooperate with, any and all applicable legal authorities. Astraler is
          entitled, except to the extent prohibited by applicable law, to disclose any information or
          materials on the Astraler Properties, including Your Content, in Astraler’s possession in
          connection with your use of the Astraler Properties, to (1) comply with applicable laws,
          legal process or governmental request; (2) enforce the Terms; (3) respond to any claims that
          Your Content violates the rights of third parties; (4) respond to your requests for customer
          service; or (5) protect the rights, property or personal safety of Astraler, its Users or
          the public, and all enforcement or other government officials, as Astraler in its sole
          discretion believes to be necessary or appropriate.</p>
          <b>Breach</b>
          <p>In the event that Astraler determines, in its sole discretion, that you have breached any
          portion of the Terms, or have otherwise demonstrated inappropriate conduct, Astraler
          reserves the right to:</p>
          <p>(a) Warn you via e-mail (to any e-mail address you have provided to Astraler) that you have
          violated the Terms;</p>
          <p>(b) Delete any of Your Content provided by you or your agent(s) to the Astraler
          Properties;</p>
          <p>(c) Discontinue your registration(s) with the Astraler Properties;</p>
          <p>(d) Discontinue your subscription to any Service;</p>
          <p>(e) Notify and/or send Your Content to and/or fully cooperate with the proper law enforcement
          authorities for further action; and/or</p>
          <p>(f) Pursue any other action which Astraler deems to be appropriate.</p>
          <b>No Subsequent Registration</b>
          <p>If your registration(s) with or ability to access the Astraler Properties is discontinued by
          Astraler due to your violation of any portion of the Terms, then you agree that you shall
          not attempt to re-register with or access the Astraler Properties through use of a
          different member name or otherwise, and you acknowledge that you will not be entitled to
          receive a refund for fees related to those Astraler Properties to which your access has
          been terminated. In the event that you violate the immediately preceding sentence, Astraler
          reserves the right, in its sole discretion, to immediately take any or all of the actions
          set forth herein without any notice or warning to you.</p>
          </div>}

          {activeTab === 'menu10' && <div id="menu10">
          <b>Electronic Communications</b>
          <p>The communications between you and Astraler use electronic means, whether you visit the
          Website or send Astraler e-mails, or whether Astraler posts notices on the Website or
          communicates with you via e-mail. For contractual purposes, you (1) consent to receive
          communications from Astraler in an electronic form; and (2) agree that all terms and
          conditions, agreements, notices, disclosures, and other communications that Astraler
          provides to you electronically satisfy any legal requirement that such communications would
          satisfy if it were to be in writing. The foregoing does not affect your statutory
          rights.</p>
          <b>Release</b>
          <p>You hereby release Astraler and its successors from claims, demands, any and all losses,
          damages, rights, and actions of any kind, including personal injuries, death, and property
          damage of any kind arising in connection with or as a result of the Terms or your use of the
          Website, the Application, the Software or the Service. If you are a California resident, you
          hereby waive California Civil Code Section 1542, which states, “A general release does not
          extend to claims which the creditor does not know or suspect to exist in his favor at the
          time of executing the release, which, if known by him must have materially affected his
          settlement with the debtor.</p>
          <b>Assignment</b>
          <p>The Terms, and your rights and obligations hereunder, may not be assigned, subcontracted,
          delegated, or otherwise transferred by you without Astraler’s prior written consent, and
          any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing
          will be null and void.</p>
          <b>Force Majeure</b>
          <p>Astraler and its licensors shall not be liable for any delay or failure to perform resulting
          from causes outside its reasonable control, including, but not limited to, acts of God; war;
          terrorism; riots; embargos; acts of civil or military authorities; fire; floods; accidents;
          strikes or shortages of transportation facilities; fuel; energy; labor; materials; problems
          with your computing or network infrastructure, hardware or product; problems with your
          internet service provider (ISP) or any sites you are attempting access through the VPN
          Service; or any electrical or other utility outage.</p>
          <b>Dispute Resolution</b>
          <p>(a) Any claim or dispute (excluding claims for injunctive or other equitable relief as set
          forth below) in connection with the Terms where the total amount of the award sought is less
          than Five Thousand U.S. Dollars (US $5,000.00) may be resolved in a cost effective manner
          through binding non-appearance-based arbitration, at the option of the party seeking relief.
          Such arbitration shall be initiated through an established alternative dispute resolution
          provider (“ADR Provider”) that offers arbitration as set forth in this section and under the
          rules of such ADR Provider, except to the extent such rules are in conflict with the Terms.
          The party demanding arbitration will propose an ADR Provider and the other party shall not
          unreasonably withhold consent to use such ADR Provider. The ADR Provider and the parties
          must comply with the following rules:</p>
          <p>(1) the arbitration shall be conducted by telephone, online and/or be solely based on written
          submissions, the specific manner shall be chosen by the party initiating the
          arbitration;</p>
          <p>(2) all arbitration proceedings shall be held in English;</p>
          <p>(3) the arbitration shall not involve any personal appearance by the parties or witnesses
          unless otherwise mutually agreed to by the parties;</p>
          <p>(4) any judgment on the award rendered by the arbitrator may be entered in any court of
          competent jurisdiction. Each party shall bear its own costs (including attorney fees) and
          disbursements arising out of the arbitration, and shall pay an equal share of the fees and
          costs of the ADR Provider. Notwithstanding the foregoing, Astraler may seek injunctive or
          other equitable relief to protect its intellectual property rights in any court of competent
          jurisdiction. Please note that the laws of the jurisdiction where you are located may be
          different from California law, including the laws governing what can legally be sold,
          bought, exported, offered or imported. You shall always comply with all the international
          and domestic laws, ordinances, regulations and statutes that are applicable to your use of
          the Astraler Properties.</p>
          <p>(b) Any other dispute (including whether the claims asserted are arbitrable) shall be
          referred to and finally determined by binding and confidential arbitration. Arbitration
          shall be subject to the Federal Arbitration Act and not any state arbitration law. The
          arbitration shall be conducted before one commercial arbitrator with substantial experience
          in resolving commercial contract disputes from the American Arbitration Association (“AAA”).
          As modified by the Terms, and unless otherwise agreed upon by the parties in writing, the
          arbitration will be governed by the AAA’s Commercial Arbitration Rules and, if the
          arbitrator deems them applicable, the Supplementary Procedures for Consumer Related Disputes
          (collectively “Rules and Procedures”).</p>
          <p>(c) You are thus GIVING UP YOUR RIGHT TO GO TO COURT to assert or defend your rights EXCEPT
          for matters that may be taken to small claims court. Your rights will be determined by a
          NEUTRAL ARBITRATOR and NOT a judge or jury. You are entitled to a FAIR HEARING, BUT the
          arbitration procedures are SIMPLER AND MORE LIMITED THAN RULES APPLICABLE IN COURT.
          Arbitrator decisions are as enforceable as any court order and are subject to VERY LIMITED
          REVIEW BY A COURT.</p>
          <p>(d) You and Astraler must abide by the following rules:</p>
          <p>(i) ANY CLAIMS BROUGHT BY YOU OR Astraler MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL
          CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
          PROCEEDING;</p>
          <p>(ii) THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS, MAY NOT OTHERWISE
          PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING, AND MAY NOT AWARD CLASS-WIDE
          RELIEF;</p>
          <p>(iii) in the event that you are able to demonstrate that the costs of arbitration will be
          prohibitive as compared to costs of litigation, Astraler will pay as much of your filing
          and hearing fees in connection with the arbitration as the arbitrator deems necessary to
          prevent the arbitration from being cost-prohibitive as compared to the cost of
          litigation;</p>
          <p>(iv) Astraler also reserves the right in its sole and exclusive discretion to assume
          responsibility for all of the costs of the arbitration;</p>
          <p>(v) the arbitrator shall honor claims of privilege and privacy recognized at law;</p>
          <p>(vi) the arbitration shall be confidential, and neither you nor we may disclose the
          existence, content or results of any arbitration, except as may be required by law or for
          the purposes of enforcement of the arbitration award;</p>
          <p>(vii) the arbitrator may award any individual relief or individual remedies that are
          permitted by applicable law;</p>
          <p>(viii) each side pays its own attorneys’ fees and expenses unless there is a statutory
          provision that requires the prevailing party to be paid its fees and litigation expenses,
          and then in such instance, the fees and costs awarded shall be determined by applicable
          law.</p>
          <p>(e) The arbitral proceedings, and all pleadings and written evidence will be in the English
          language. Any written evidence originally in a language other than English will be submitted
          in English translation accompanied by the original or true copy thereof. The English
          language version will control. The arbitrator shall issue a written award and statement of
          decision describing the essential findings and conclusions on which the award is based,
          including the calculation of any damages awarded. The arbitrator will not have authority to
          award damages in excess of the amount, or other than the types, allowed by Section 8 of the
          Terms. Judgment on the award of the arbitrator may be entered by any court of competent
          jurisdiction. The arbitrator also shall be authorized to grant any temporary, preliminary or
          permanent equitable remedy or relief it deems just and equitable and within the scope of the
          Terms, including, without limitation, an injunction or order for specific performance. The
          arbitration award shall be final and binding upon the parties without appeal or review
          except as permitted by California law or United States federal law.</p>
          <p>- Notwithstanding the foregoing, either you or Astraler may bring an individual action in
          small claims court. Further, claims of defamation, violation of the Computer Fraud and Abuse
          Act, and infringement or misappropriation of the other party’s patent, copyright, trademark,
          or trade secret shall not be subject to this arbitration agreement. Such claims shall be
          exclusively brought in the state or federal courts located in San Francisco County,
          California. Additionally, notwithstanding this agreement to arbitrate, either party may seek
          emergency equitable relief before the state or federal courts located in San Francisco
          County, California, in order to maintain the status quo pending arbitration, and hereby
          agree to submit to the exclusive personal jurisdiction of the courts located within San
          Francisco County, California for such purpose. A request for interim measures shall not be
          deemed a waiver of the right to arbitrate.</p>
          <p>(f) With the exception of (d)(i) and (ii) above (prohibiting arbitration on a class or
          collective basis), if any part of this arbitration provision is deemed to be invalid,
          unenforceable, or illegal, or otherwise conflicts with the Rules and Procedures, then the
          balance of this arbitration provision shall remain in effect and shall be construed in
          accordance with its terms as if the invalid, unenforceable, illegal or conflicting provision
          were not contained herein. If, however, either (d)(i) or (ii) is found to be invalid,
          unenforceable or illegal, then the entirety of this arbitration provision shall be null and
          void, and neither you nor Astraler shall be entitled to arbitration. If for any reason, a
          claim proceeds in court rather than in arbitration, the dispute shall be exclusively brought
          in state or federal court in San Francisco County, California. By using the Astraler
          Properties in any manner, you agree to the above arbitration provision.
          For more information on AAA, its Rules and Procedures, and how to file an arbitration claim,
          you may call AAA at 800-778-7879 or visit the AAA website at <a href="http://www.adr.org">http://www.adr.org</a>
          .</p>
          <b>Choice of Law and Venue</b>
          <p>The Terms and any action related thereto will be governed and interpreted by and under the
          laws of the State of California, without giving effect to any conflict of laws principles
          that require the application of the law of a different state. You hereby expressly consent
          to the personal jurisdiction and venue in the state and federal courts for the county in
          which Astraler’s principal place of business is located for any lawsuit filed against you
          by Astraler arising from or related to the Terms.</p>
          <b>Notice</b>
          <p>Where Astraler requires that you provide an e-mail address, you are responsible for
          providing Astraler with your most current e-mail address. In the event that the last e-mail
          address you provided to Astraler is not valid, or for any reason is not capable of
          delivering to you any notices required/permitted by the Terms, Astraler’s dispatch of the
          e-mail containing such notice will nonetheless constitute effective notice. You may give
          notice to Astraler at the following address: 178 Castro St., San Francisco, CA 94114. Such
          notice shall be deemed given when received by Astraler by letter delivered by nationally
          recognized overnight delivery service or first class postage prepaid mail at the above
          address.</p>
          <b>Waiver</b>
          <p>Any waiver or failure to enforce any provision of the Terms on one occasion will not be
          deemed a waiver of any other provision or of such provision on any other occasion.</p>
          <b>Severability</b>
          <p>If any provision of the Terms is, for any reason, held to be invalid or unenforceable, the
          other provisions of the Terms will remain enforceable, and the invalid or unenforceable
          provision will be deemed modified so that it is valid and enforceable to the maximum extent
          permitted by law.</p>
          <b>App Stores</b>
          <p>You acknowledge and agree that the availability of the Application and the Services is
          dependent on the third party from whom you received the Application license, e.g., the Apple
          iPhone or Android app stores (“App Store”). You acknowledge and agree that the Terms are
          between you and Astraler only, and not with the App Store. Astraler, not the App Store, is
          solely responsible for the Astraler Properties, including the Application, the contents
          thereof, maintenance, support services, and warranty therefor, and addressing any claims
          relating thereto (e.g., product liability, legal compliance or intellectual property
          infringement). In order to use the Application, you must have access to a wireless network,
          and you agree to pay all fees associated with such access. You also agree to pay all fees
          (if any) charged by the App Store in connection with the Astraler Properties, including the
          Application. You agree to comply with, and your license to use the Application is
          conditioned upon your compliance with, all applicable third- party terms of agreement (e.g.,
          the App Store’s terms and policies) when using the Astraler Properties, including the
          Application. You acknowledge that the App Store (and its subsidiaries) are third-party
          beneficiaries of the Terms and will have the right to enforce them.</p>
          <b>Accessing and Download the Application from iTunes</b>
          <p>The following applies to any App Store Sourced Application accessed through or downloaded
          from the Apple App Store:</p>
          <p>(a) You acknowledge and agree that (i) the Terms are concluded between you and Astraler
          only, and not Apple, and (ii) Astraler, not Apple, is solely responsible for the App Store
          Sourced Application and content thereof. Your use of the App Store Sourced Application must
          comply with the App Store Terms of Service.</p>
          <p>(b) You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and
          support services with respect to the App Store Sourced Application.</p>
          <p>(c) In the event of any failure of the App Store Sourced Application to conform to any
          applicable warranty, you may notify Apple, and Apple will refund the purchase price for the
          App Store Sourced Application to you and to the maximum extent permitted by applicable law,
          Apple will have no other warranty obligation whatsoever with respect to the App Store
          Sourced Application. As between Astraler and Apple, any other claims, losses, liabilities,
          damages, costs or expenses attributable to any failure to conform to any warranty will be
          the sole responsibility of Astraler.</p>
          <p>(d) You and Astraler acknowledge that, as between Astraler and Apple, Apple is not
          responsible for addressing any claims you have or any claims of any third party relating to
          the App Store Sourced Application or your possession and use of the App Store Sourced
          Application, including, but not limited to: (i) product liability claims; (ii) any claim
          that the App Store Sourced Application fails to conform to any applicable legal or
          regulatory requirement; and (iii) claims arising under consumer protection or similar
          legislation.</p>
          <p>(e) You and Astraler acknowledge that, in the event of any third party claim that the App
          Store Sourced Application or your possession and use of that App Store Sourced Application
          infringes that third party’s intellectual property rights, as between Astraler and Apple,
          Astraler, not Apple, will be solely responsible for the investigation, defense, settlement
          and discharge of any such intellectual property infringement claim to the extent required by
          the Terms.</p>
          <p>(f) You and Astraler acknowledge and agree that Apple, and Apple’s subsidiaries, are third
          party beneficiaries of the Terms as related to your license of the App Store Sourced
          Application, and that, upon your acceptance of the terms and conditions of the Terms, Apple
          will have the right (and will be deemed to have accepted the right) to enforce the Terms as
          related to your license of the App Store Sourced Application against you as a third party
          beneficiary thereof.</p>
          <p>(g) Without limiting any other terms of the Terms, you must comply with all applicable third
          party terms of agreement when using the App Store Sourced Application.</p>
          <b>Export Control</b>
          <p>You may not use, export, import, or transfer the Astraler Properties except as authorized by
          U.S. law, the laws of the jurisdiction in which you obtained the Astraler Properties, and
          any other applicable laws. In particular, but without limitation, the Astraler Properties
          may not be exported or re-exported (a) into any United States embargoed countries, or (b) to
          anyone on the U.S. Treasury Department’s list of Specially Designated Nationals or the U.S.
          Department of Commerce’s Denied Person’s List or Entity List. By using the Astraler
          Properties, you represent and warrant that (i) you are not located in a country that is
          subject to a U.S. Government embargo, or that has been designated by the U.S. Government as
          a “terrorist supporting” country and (ii) you are not listed on any U.S. Government list of
          prohibited or restricted parties. You also will not use the Astraler Properties for any
          purpose prohibited by U.S. law, including the development, design, manufacture or production
          of missiles, nuclear, chemical or biological weapons. You acknowledge and agree that
          products, services or technology provided by Astraler are subject to the export control
          laws and regulations of the United States. You shall comply with these laws and regulations
          and shall not, without prior U.S. government authorization, export, re-export, or transfer
          the Astraler products, services or technology, either directly or indirectly, to any
          country in violation of such laws and regulations.</p>
          <b>International Users</b>
          <p>The Astraler Properties can be accessed from countries around the world and may contain
          references to Services and Content that are not available in your country. These references
          do not imply that Astraler intends to announce such Services or Content in your country.
          The Astraler Properties are controlled and offered by Astraler from its facilities in the
          United States of America. Astraler makes no representations that Astraler Properties are
          appropriate or available for use in other locations. Those who access or use the Astraler
          Properties from other jurisdictions do so at their own volition and are responsible for
          compliance with local law.</p>
          <b>Questions, Complaints, and Claims</b>
          <p>If you have any questions, complaints or claims, please contact us at:
          support@getPhotohub.com. We will do our best to address your concerns. If you feel that
          your concerns have been addressed incompletely, we invite you to let us know for further
          investigation.</p>
          <b>Consumer Complaints</b>
          <p>In accordance with California Civil Code §1789.3, you may report complaints to the Complaint
          Assistance Unit of the Division of Consumer Service of the California Department of Consumer
          Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone
          at (800) 952-5210.</p>
          <b>Entire Agreement</b>
          <p>The Terms are the final, complete and exclusive agreement of the parties with respect to the
          subject matter hereof and supersedes and merges all prior discussions between the parties
          with respect to such subject matter.</p>
          </div>}

          {activeTab === 'menu11' && <div id="menu11">
          <p>The following provisions shall apply only if you are located in the countries listed
          below.</p>
          <b>United Kingdom</b>
          <p>A third party who is not a party to the Terms has no right under the Contracts (Rights of
          Third Parties) Act 1999 to enforce any provision of the Terms, but this does not affect any
          right or remedy of such third party which exists or is available apart from that Act.</p>
          <b>Germany</b>
          <p>Notwithstanding anything to the contrary in Section 8, Astraler is also not liable for acts
          of simple negligence (unless they cause injuries to or death of any person), except when
          they are caused by a breach of any substantial contractual obligations (vertragswesentliche
          Pflichten).</p>
          </div>}
        </Col>
      </Row>
    </Container>
  );
}

export default Terms;
