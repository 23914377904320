import {
  Container,
  Row,
  Col,
} from 'react-bootstrap'
import conceptImg from '../assets/img/concept.png'
import productionImg from '../assets/img/production.png'
import launchImg from '../assets/img/launch.png'

function WhatWeDo(props) {
  return (
    <Container className="WhatWeDo" {...props}>
      <div className="Hairline-Large text-center">what we do</div>
      <h2 className="text-center mt-2 mb-5">From <span className="text-red">idea</span> to market</h2>
      <Row className="mt-2">
        <Col lg={4} className="mb-4 d-lg-flex align-items-stretch">
          <div className="bg-silver text-center w-100">
            <div><img className="w-25p" src={conceptImg} alt="Concept" /></div>
            <div className="Text-Body-1 mt-3">Concept</div>
            <div className="Text-Body-2 mt-2">Ideas are brainstormed and nurtured</div>
          </div>
        </Col>
        <Col lg={4} className="mb-4 d-lg-flex align-items-stretch">
          <div className="bg-silver text-center w-100">
            <div><img className="w-25p" src={productionImg} alt="Production" /></div>
            <div className="Text-Body-1 mt-3">Production</div>
            <div className="Text-Body-2 mt-2">Design and develop features</div>
          </div>
        </Col>
        <Col lg={4} className="mb-4 d-lg-flex align-items-stretch">
          <div className="bg-silver text-center w-100">
            <div><img className="w-25p" src={launchImg} alt="Launch" /></div>
            <div className="Text-Body-1 mt-3">Launch</div>
            <div className="Text-Body-2 mt-2">Get it out there for the world to see</div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default WhatWeDo;
