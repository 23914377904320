import {
  Container,
  Row,
  Col,
} from 'react-bootstrap'
import benefit1Img from '../assets/img/benefit1.png'
import benefit2Img from '../assets/img/benefit2.png'
import benefit3Img from '../assets/img/benefit3.png'
import benefit4Img from '../assets/img/benefit4.png'

function Benefits(props) {
  return (
    <Container className="Benefits" {...props}>
      <Row>
        <Col xs={12} lg={4}>
          <h2 className="mb-5 pe-3">Perks and Benefits</h2>
        </Col>
        <Col xs={12} lg={8}>
          <Row className="mb-5">
            <Col>
              <div className="mx-1 mb-4">
                <img src={benefit1Img} alt="Unique experience"/>
              </div>
              <div className="Text-Body-1 mb-2">Unique experience</div>
              <div className="Text-Body-2 mb-5">Grow and learn everyday with the large variety of projects</div>
            </Col>
            <Col>
              <div className="mx-1 mb-4">
                <img src={benefit2Img} alt="Life insurance"/>
              </div>
              <div className="Text-Body-1 mb-2">Life insurance</div>
              <div className="Text-Body-2 mb-5">For your valued well-being and peace of mind</div>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>
              <div className="mx-1 mb-4">
                <img src={benefit3Img} alt="Work-life balance"/>
              </div>
              <div className="Text-Body-1 mb-2">Work-life balance</div>
              <div className="Text-Body-2 mb-5">7.5-hour workday, 5 days a week.<br/>Spend more quality time with your loved ones</div>
            </Col>
            <Col>
              <div className="mx-1 mb-4">
                <img src={benefit4Img} alt="Team retreat"/>
              </div>
              <div className="Text-Body-1 mb-2">Team retreat</div>
              <div className="Text-Body-2 mb-5">We love fun adventures. Get to know your colleagues through meaningful outings and vacations.</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Benefits;
