import Hero from './components/Hero';
import background1Img from './assets/img/bg1.jpg'
import background2Img from './assets/img/bg2.png'
import WhatWeDo from './components/WhatWeDo';
import OurProducts from './components/OurProducts';
import OurTeam from './components/OurTeam';
import JoinOurTeam from './components/JoinOurTeam';
import Benefits from './components/Benefits';
import Life from './components/Life';
import FadeInSection from './components/FadeInSection';

function Home() {
  return (
    <>
      <Hero/>
      <div className="Background">
        <img src={background1Img} alt="Background"/>
      </div>
      <div className="Line-Break"></div>
      <FadeInSection>
        <WhatWeDo/>
      </FadeInSection>
      <div className="Line-Break"></div>
      <FadeInSection>
        <OurProducts/>
      </FadeInSection>
      <div className="Background">
        <img src={background2Img} alt="Background"/>
      </div>
      <FadeInSection>
        <OurTeam/>
      </FadeInSection>
      <FadeInSection>
        <JoinOurTeam id="jobs"/>
      </FadeInSection>
      <FadeInSection>
        <Benefits id="benefits"/>
      </FadeInSection>
      <FadeInSection>
        <Life/>
      </FadeInSection>
    </>
  );
}

export default Home;
