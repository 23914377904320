import { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap'
import { get } from '../api'

function JoinOurTeam(props) {
  const [jobs, setJobs] = useState([])
  useEffect(() => {
    if (localStorage.getItem('jobs')) {
      try {
        const jobs = JSON.parse(localStorage.getItem('jobs'))
        setJobs(jobs)
      } catch (err) {}
    }
    async function fetchData() {
      const { data } = await get('/api/v1/jobs?limit=1000&open=true&sort=-priority')
      if (!data) {
        return
      }
      setJobs(data)
      localStorage.setItem('jobs', JSON.stringify(data.map(item => ({
        _id: item._id,
        name: item.name,
        location: item.location,
      }))))
    }
    fetchData()
  }, [])

  return (
    <Container className="JoinOurTeam pt-5" {...props}>
      <h2 className="text-center mt-3">Join our team</h2>
      {!!jobs.length ? (
        <>
          <div className="Text-Body-2 text-center mt-1 mb-5">
            We could talk all day about how much fun it is being part of the Astraler team.<br/>
            But really there's only one way to find out...
          </div>
          <div className="Text-Body-1 mt-5 mb-3 text-primary">{jobs.length} open position{jobs.length === 1 ? '' : 's'}</div>
          <hr/>
        </>
      ) : (
        <>
          <div className="Text-Body-2 text-center mt-1 mb-5">
            We don't have any job vacancies at the moment. Follow us for any future opportunities!
          </div>
          <center>
            <a className="btn btn-outline-secondary btn-lg" href="https://www.facebook.com/astralertechnology" target="_blank" rel="noreferrer">Follow us on social</a>
          </center>
        </>
      )}
      {!!jobs.length && jobs.map(item => (
        <div key={item._id}>
          <Row className="my-5">
            <Col xs={12} md={6} className="Text-Body-1 d-flex align-items-center">{item.name}</Col>
            <Col xs={12} md={3} className="Text-Body-2 d-flex align-items-center">{item.location}</Col>
            <Col xs={12} md={3} className="d-md-flex align-items-center justify-content-end mt-3 mt-md-0">
              <Button variant="outline-secondary" size="lg" href={`/jobs/${item.slug || item._id}`}>
                See Details
                <i className="fa-solid fa-right-long ms-2 fs-10"></i>
              </Button>
            </Col>
          </Row>
          <hr/>
        </div>
      ))}
    </Container>
  );
}

export default JoinOurTeam;
