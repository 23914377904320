import { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Carousel,
} from 'react-bootstrap'
import { apiUrl, get } from '../api'
import { chunk } from 'lodash'

function OurTeam(props) {
  const [members, setMembers] = useState([])
  useEffect(() => {
    if (localStorage.getItem('members')) {
      try {
        const members = JSON.parse(localStorage.getItem('members'))
        setMembers(members)
      } catch (err) {}
    }
    async function fetchData() {
      const { data } = await get('/api/v1/members?limit=1000&sort=-priority')
      if (!data) {
        return
      }
      setMembers(data)
      localStorage.setItem('members', JSON.stringify(data))
    }
    fetchData()
  }, [])
  return (
    <div className="OurTeam" {...props}>
      <Container>
        <Row>
          <Col md={4}>
            <h2>Meet<br/>our team</h2>
          </Col>
          <Col md={8}>
            <div className="d-none d-md-block mt-4">&nbsp;</div>
            <div className="Text-Body-2">
              Our team is jam packed with creative, smart and bubbly people. We inspire each other to improve daily, and be the best and happiest we can be.
            </div>
          </Col>
        </Row>
      </Container>
      <Carousel className="my-5 pb-5 d-none d-sm-block">
        {!!members.length && chunk(members, 4).map((items, chunkIndex) => (
          <Carousel.Item key={`chunk-${chunkIndex}`}>
            <Container>
              <Row>
                {!!items.length && items.map(item => (
                  <Col xs={6} sm={3} key={item._id}>
                    <div className="my-4 Image-Square">
                      <img className="d-block w-100 rounded-5" src={apiUrl + item.image.src} alt="First slide" />
                    </div>
                    <div className="Text-Body-1 text-center">{item.name}</div>
                    <div className="Text-Body-2 text-center">{item.position}</div>
                  </Col>
                ))}
              </Row>
            </Container>
          </Carousel.Item>
        ))}
      </Carousel>
      <Carousel className="my-5 pb-5 d-block d-sm-none">
        {!!members.length && chunk(members, 2).map((items, chunkIndex) => (
          <Carousel.Item key={`chunk-${chunkIndex}`}>
            <Container>
              <Row>
                {!!items.length && items.map((item, index) => (
                  <Col xs={6} sm={3} key={item._id}>
                    <div className="my-4 Image-Square">
                      <img className="d-block w-100 rounded-5" src={apiUrl + item.image.src} alt="First slide" />
                    </div>
                    <div className="User-Info">
                      <div className="Text-Body-2-Bold text-center">{item.name}</div>
                      <div className="Text-Caption-2 text-center">{item.position}</div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default OurTeam;
